/**
 * @typedef {import('./index').default} Ultraviolet
 */

/**
 *
 * @param {Ultraviolet} ctx
 */
export function attributes(ctx, meta = ctx.meta) {
    const { html, js, attributePrefix } = ctx;
    const origPrefix = attributePrefix + '-attr-';

    html.on('attr', (attr, type) => {
        if (
            attr.node &&
            attr.node.hasAttribute &&
            attr.node.hasAttribute(ctx.ignoreAttr)
        )
            return;

        if (
            attr.node.tagName === 'base' &&
            attr.name === 'href' &&
            attr.options.document
        ) {
            meta.base = new URL(attr.value, meta.url);
        }

        if (type === 'rewrite' && isUrl(attr.name, attr.tagName)) {
            attr.node.setAttribute(origPrefix + attr.name, attr.value);
            attr.value = ctx.rewriteUrl(attr.value, meta);
        }

        if (type === 'rewrite' && isSrcset(attr.name)) {
            attr.node.setAttribute(origPrefix + attr.name, attr.value);
            attr.value = html.wrapSrcset(attr.value, meta);
        }

        if (type === 'rewrite' && isHtml(attr.name)) {
            attr.node.setAttribute(origPrefix + attr.name, attr.value);
            attr.value = html.rewrite(attr.value, {
                ...meta,
                document: true,
                injectHead: attr.options.injectHead || [],
            });
        }

        if (type === 'rewrite' && isStyle(attr.name)) {
            attr.node.setAttribute(origPrefix + attr.name, attr.value);
            attr.value = ctx.rewriteCSS(attr.value, {
                context: 'declarationList',
            });
        }

        if (type === 'rewrite' && isForbidden(attr.name)) {
            attr.name = origPrefix + attr.name;
        }

        if (type === 'rewrite' && isEvent(attr.name)) {
            attr.node.setAttribute(origPrefix + attr.name, attr.value);
            attr.value = js.rewrite(attr.value, meta);
        }

        if (type === 'source' && attr.name.startsWith(origPrefix)) {
            if (attr.node.hasAttribute(attr.name.slice(origPrefix.length)))
                attr.node.removeAttribute(attr.name.slice(origPrefix.length));
            attr.name = attr.name.slice(origPrefix.length);
        }

        /*
        if (isHtml(attr.name)) {

        };

        if (isStyle(attr.name)) {

        };

        if (isSrcset(attr.name)) {

        };
        */
    });
}

/**
 *
 * @param {Ultraviolet} ctx
 */
export function text(ctx) {
    const { html, js, css } = ctx;

    html.on('text', (text, type) => {
        if (text.element.tagName === 'script') {
            text.value =
                type === 'rewrite'
                    ? js.rewrite(text.value)
                    : js.source(text.value);
        }

        if (text.element.tagName === 'style') {
            text.value =
                type === 'rewrite'
                    ? css.rewrite(text.value)
                    : css.source(text.value);
        }
    });
    return true;
}

export function isUrl(name, tag) {
    return (
        (tag === 'object' && name === 'data') ||
        [
            'src',
            'href',
            'ping',
            'movie',
            'action',
            'poster',
            'profile',
            'background',
        ].indexOf(name) > -1
    );
}

export function isEvent(name) {
    return (
        [
            'onafterprint',
            'onbeforeprint',
            'onbeforeunload',
            'onerror',
            'onhashchange',
            'onload',
            'onmessage',
            'onoffline',
            'ononline',
            'onpagehide',
            'onpopstate',
            'onstorage',
            'onunload',
            'onblur',
            'onchange',
            'oncontextmenu',
            'onfocus',
            'oninput',
            'oninvalid',
            'onreset',
            'onsearch',
            'onselect',
            'onsubmit',
            'onkeydown',
            'onkeypress',
            'onkeyup',
            'onclick',
            'ondblclick',
            'onmousedown',
            'onmousemove',
            'onmouseout',
            'onmouseover',
            'onmouseup',
            'onmousewheel',
            'onwheel',
            'ondrag',
            'ondragend',
            'ondragenter',
            'ondragleave',
            'ondragover',
            'ondragstart',
            'ondrop',
            'onscroll',
            'oncopy',
            'oncut',
            'onpaste',
            'onabort',
            'oncanplay',
            'oncanplaythrough',
            'oncuechange',
            'ondurationchange',
            'onemptied',
            'onended',
            'onerror',
            'onloadeddata',
            'onloadedmetadata',
            'onloadstart',
            'onpause',
            'onplay',
            'onplaying',
            'onprogress',
            'onratechange',
            'onseeked',
            'onseeking',
            'onstalled',
            'onsuspend',
            'ontimeupdate',
            'onvolumechange',
            'onwaiting',
        ].indexOf(name) > -1
    );
}

/**
 *
 * @param {Ultraviolet} ctx
 */
export function injectHead(ctx) {
    const { html } = ctx;
    html.on('element', (element, type) => {
        if (type !== 'rewrite') return false;
        if (element.tagName !== 'head') return false;
        if (!('injectHead' in element.options)) return false;

        element.childNodes.unshift(...element.options.injectHead);
    });
}

export function createJsInject(
    bareURL = '',
    bareData = {},
    cookies = '',
    referrer = '',
    env = {}
) {
    return (
        `self.__uv$bareData = ${JSON.stringify(bareData)};` +
        `self.__uv$cookies = ${JSON.stringify(cookies)};` +
        `self.__uv$referrer = ${JSON.stringify(referrer)};` +
        `self.__uv$bareURL = ${JSON.stringify(bareURL)}; ` +
        `self.__uv$env = ${JSON.stringify(env)}; `
    );
}

export function createYaMetricInject(yaId) {
    return `(function (m, e, t, r, i, k, a) {
        m[i] =
            m[i] ||
            function () {
                (m[i].a = m[i].a || []).push(arguments);
            };
        m[i].l = 1 * new Date();
        
        for (var j = 0; j < document.scripts.length; j++) {
            if (document.scripts[j].src === r) {
                return;
            }
        }
        (k = e.createElement(t)),
            (a = e.getElementsByTagName(t)[0]),
            k.setAttribute('__uv-ignore', 'true'),
            (k.async = 1),
            (k.src = r),
            a.parentNode.insertBefore(k, a);
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
    
    ym(${yaId}, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
    });`;
}

export function createHtmlInject(
    handlerScript,
    bundleScript,
    clientScript,
    configScript,
    toolbarAssets,
    bareURL,
    bareData,
    cookies,
    referrer,
    env,
    metrics
) {
    return [
        {
            tagName: 'script',
            nodeName: 'script',
            childNodes: [
                {
                    nodeName: '#text',
                    value: createJsInject(
                        bareURL,
                        bareData,
                        cookies,
                        referrer,
                        env
                    ),
                },
            ],
            attrs: [
                {
                    name: '__uv-script',
                    value: '1',
                    skip: true,
                },
            ],
            skip: true,
        },
        {
            tagName: 'script',
            nodeName: 'script',
            childNodes: [],
            attrs: [
                { name: 'src', value: bundleScript, skip: true },
                {
                    name: '__uv-script',
                    value: '1',
                    skip: true,
                },
            ],
        },
        {
            tagName: 'script',
            nodeName: 'script',
            childNodes: [],
            attrs: [
                { name: 'src', value: clientScript, skip: true },
                {
                    name: '__uv-script',
                    value: '1',
                    skip: true,
                },
            ],
        },
        {
            tagName: 'script',
            nodeName: 'script',
            childNodes: [],
            attrs: [
                { name: 'src', value: configScript, skip: true },
                {
                    name: '__uv-script',
                    value: '1',
                    skip: true,
                },
            ],
        },
        ...(toolbarAssets && toolbarAssets.script
            ? [
                  {
                      tagName: 'script',
                      nodeName: 'script',
                      childNodes: [],
                      attrs: [
                          {
                              name: 'src',
                              value: toolbarAssets.script,
                              skip: true,
                          },
                          {
                              name: '__uv-script',
                              value: '1',
                              skip: true,
                          },
                          {
                              name: 'type',
                              value: 'module',
                              skip: true,
                          },
                      ],
                  },
              ]
            : []),
        ...(toolbarAssets && toolbarAssets.style
            ? [
                  {
                      tagName: 'link',
                      nodeName: 'link',
                      childNodes: [],
                      attrs: [
                          {
                              name: 'href',
                              value: toolbarAssets.style,
                              skip: true,
                          },
                          { name: 'rel', value: 'stylesheet', skip: true },
                          {
                              name: '__uv-style',
                              value: '1',
                              skip: true,
                          },
                      ],
                  },
              ]
            : []),
        {
            tagName: 'script',
            nodeName: 'script',
            childNodes: [],
            attrs: [
                { name: 'src', value: handlerScript, skip: true },
                {
                    name: '__uv-script',
                    value: '1',
                    skip: true,
                },
            ],
        },
        ...(metrics && metrics.yaId
            ? (() => {
                  const a = {
                      tagName: 'script',
                      nodeName: 'script',
                      namespaceURI: 'http://www.w3.org/1999/xhtml',
                      childNodes: [
                          {
                              nodeName: '#text',
                              value: createYaMetricInject(metrics.yaId),
                              get parentNode() {
                                return a
                              }
                          },
                      ],
                      attrs: [
                          {
                              name: '__uv-script',
                              value: '1',
                              skip: true,
                          },
                          {
                              name: 'type',
                              value: 'text/javascript',
                              skip: true,
                          },
                      ],
                      skip: true,
                  };
                  return [a];
              })()
            : []),
    ];
}

export function isForbidden(name) {
    return (
        ['http-equiv', 'integrity', 'sandbox', 'nonce', 'crossorigin'].indexOf(
            name
        ) > -1
    );
}

export function isHtml(name) {
    return name === 'srcdoc';
}

export function isStyle(name) {
    return name === 'style';
}

export function isSrcset(name) {
    return name === 'srcset' || name === 'imagesrcset';
}
